/*
We're constantly improving the code you see. 
Please share your feedback here: https://form.asana.com/?k=uvp-HPgd3_hyoXRBw1IcNg&d=1152665201300829
*/

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Screen = ({ page, style, className, screenClassName }) => {
  return (
    <div className={`screen ${style} ${className}`}>
      <div className={`screen-2 ${screenClassName}`}>
        {page === "one" && style === "default" && (
          <>
            Screen
            <br />1
          </>
        )}

        {page === "two" && style === "default" && (
          <>
            Screen
            <br />2
          </>
        )}

        {page === "three" && style === "default" && (
          <>
            Screen
            <br />3
          </>
        )}

        {style === "default" && page === "four" && (
          <>
            Screen
            <br />4
          </>
        )}

        {page === "five" && style === "default" && (
          <>
            Screen
            <br />5
          </>
        )}

        {page === "six" && style === "default" && (
          <>
            Screen
            <br />6
          </>
        )}

        {style === "default" && page === "seven" && (
          <>
            Screen
            <br />7
          </>
        )}

        {page === "eight" && style === "default" && (
          <>
            Screen
            <br />8
          </>
        )}

        {page === "nine" && style === "default" && (
          <>
            Screen
            <br />9
          </>
        )}

        {page === "ten" && style === "default" && (
          <>
            Screen
            <br />
            10
          </>
        )}

        {style === "with-frame" && (
          <div className="place-your-screen">
            <div className="select-and-paste">
              {page === "one" && (
                <>
                  Screen
                  <br />1
                </>
              )}

              {page === "two" && (
                <>
                  Screen
                  <br />2
                </>
              )}

              {page === "three" && (
                <>
                  Screen
                  <br />3
                </>
              )}

              {page === "four" && (
                <>
                  Screen
                  <br />4
                </>
              )}

              {page === "five" && (
                <>
                  Screen
                  <br />5
                </>
              )}

              {page === "six" && (
                <>
                  Screen
                  <br />6
                </>
              )}

              {page === "seven" && (
                <>
                  Screen
                  <br />7
                </>
              )}

              {page === "eight" && (
                <>
                  Screen
                  <br />8
                </>
              )}

              {page === "nine" && (
                <>
                  Screen
                  <br />9
                </>
              )}

              {page === "ten" && (
                <>
                  Screen
                  <br />
                  10
                </>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

Screen.propTypes = {
  page: PropTypes.oneOf(["seven", "two", "ten", "three", "nine", "four", "one", "five", "eight", "six"]),
  style: PropTypes.oneOf(["with-frame", "default"]),
};
