import React, {useEffect} from "react";
import { Screen } from "../../components/Screen";
import "./style.css";

export const Element = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="element">
      <div className="intro">
        <div className="overlap">
          <img className="img" alt="Element" src="/img/001.svg" />
          <div className="overlap-wrapper">
            <div className="overlap-group">
              <Screen
                className="screen-instance"
                page="one"
                screenClassName="design-component-instance-node"
                style="default"
              />
              <div className="main">
                <div className="div">
                  <div className="rectangle" />
                  <div className="group">
                    <div className="overlap-2">
                      <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-3.png" />
                      <div className="frame">
                        <img className="ellipse" alt="Ellipse" src="/img/ellipse-1.png" />
                        <div className="frame-2">
                          <div className="div-wrapper">
                            <div className="text-wrapper">Honda Activa 6G</div>
                          </div>
                          <div className="frame-3">
                            <div className="frame-3">
                              <div className="text-wrapper-2">Standard</div>
                              <img
                                className="keyboard-arrow-down"
                                alt="Keyboard arrow down"
                                src="/img/keyboard-arrow-down-1.svg"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-wrapper">
                        <div className="frame-4">
                          <div className="group-2">
                            <p className="p">
                              <span className="span">₹</span>
                              <span className="text-wrapper-3"> 76,710</span>
                            </p>
                            <div className="group-3">
                              <div className="text-wrapper-4">EMI</div>
                              <div className="text-wrapper-5">₹ Rs.733 / month</div>
                            </div>
                          </div>
                          <div className="frame-5">
                            <div className="overlap-group-wrapper">
                              <div className="overlap-group-2">
                                <div className="text-wrapper-6">4.8</div>
                                <div className="text-wrapper-7">Rating</div>
                              </div>
                            </div>
                            <div className="overlap-group-wrapper">
                              <div className="overlap-group-3">
                                <div className="text-wrapper-8">110</div>
                                <div className="text-wrapper-9">cc</div>
                              </div>
                            </div>
                            <div className="group-wrapper">
                              <div className="group-4">
                                <div className="overlap-group-3">
                                  <div className="text-wrapper-10">50</div>
                                  <div className="text-wrapper-11">kmpl</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-6">
                        <div className="text-wrapper-12">Overview</div>
                      </div>
                      <div className="frame-7">
                        <div className="text-wrapper-13">Expert Reviews</div>
                      </div>
                      <div className="frame-8">
                        <div className="text-wrapper-14">User Reviews</div>
                      </div>
                    </div>
                    <div className="frame-9">
                      <div className="text-wrapper-15">About</div>
                      <div className="frame-10">
                        <p className="text-wrapper-16">
                          Honda Activa 6G is a mileage scooter available at a starting price of Rs. 70,321 in India. It
                          is available in 4 variants and 8 colours with top variant price starting from Rs. 73,583. The
                          Honda Activa 6G is powered by 109.51cc BS6 engine which develops a power of 7.68 bhp and a
                          torque of 8.79 Nm.
                        </p>
                        <div className="frame-11">
                          <div className="text-wrapper-17">Read More</div>
                          <img className="keyboard-arrow-down-2" alt="Keyboard arrow down" src="/img/star-1.png" />
                        </div>
                      </div>
                    </div>
                    <div className="group-5">
                      <div className="frame-12">
                        <div className="overlap-group-4">
                          <img className="rectangle-3" alt="Rectangle" src="/img/star-1.png" />
                          <img className="line" alt="Line" src="/img/star-1.png" />
                        </div>
                        <div className="group-6">
                          <div className="frame-13">
                            <div className="text-wrapper-18">Refined engine</div>
                            <div className="text-wrapper-19">Metal body panels</div>
                            <div className="text-wrapper-20">Vast service network</div>
                          </div>
                          <div className="frame-14">
                            <div className="thumb-up-wrapper">
                              <img className="thumb-up" alt="Thumb up" src="/img/star-1.png" />
                            </div>
                            <div className="text-wrapper-21">Good Things</div>
                          </div>
                        </div>
                        <div className="group-7">
                          <div className="frame-15">
                            <div className="text-wrapper-22">Brakes need more bite</div>
                            <div className="text-wrapper-23">Stiff rear suspension</div>
                            <div className="text-wrapper-24">Lacks modern features</div>
                          </div>
                          <div className="frame-16">
                            <div className="img-wrapper">
                              <img className="thumb-up-2" alt="Thumb up" src="/img/star-1.png" />
                            </div>
                            <div className="text-wrapper-25">Could be Better</div>
                          </div>
                        </div>
                      </div>
                      <div className="group-8">
                        <img className="rectangle-4" alt="Rectangle" src="/img/star-1.png" />
                        <div className="frame-17">
                          <p className="text-wrapper-26">
                            Take a Live Showroom Walkthrough and book your free doorstep test ride now!
                          </p>
                          <div className="frame-18">
                            <div className="text-wrapper-27">Let’s go!</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="group-9">
                      <div className="overlap-3">
                        <div className="rectangle-5" />
                        <div className="group-10">
                          <img className="rectangle-6" alt="Rectangle" src="/img/star-1.png" />
                          <div className="group-11">
                            <div className="frame-19">
                              <div className="ellipse-2" />
                              <div className="text-wrapper-28">110cc</div>
                            </div>
                            <div className="frame-20">
                              <div className="ellipse-2" />
                              <div className="text-wrapper-29">50kmpl</div>
                            </div>
                            <div className="frame-21">
                              <img className="star" alt="Star" src="/img/star-1.png" />
                              <div className="text-wrapper-30">4.5</div>
                            </div>
                            <div className="text-wrapper-31">TVS Jupiter</div>
                          </div>
                          <div className="text-wrapper-32">Rs. 65,673 - 79,442</div>
                        </div>
                        <div className="group-12">
                          <div className="group-13">
                            <img className="rectangle-7" alt="Rectangle" src="/img/star-1.png" />
                            <div className="group-14">
                              <div className="frame-22">
                                <div className="ellipse-2" />
                                <div className="text-wrapper-28">110cc</div>
                              </div>
                              <div className="frame-23">
                                <div className="ellipse-2" />
                                <div className="text-wrapper-29">50kmpl</div>
                              </div>
                              <div className="frame-24">
                                <img className="star-2" alt="Star" src="/img/star-1.png" />
                                <div className="text-wrapper-30">4.8</div>
                              </div>
                              <div className="text-wrapper-33">Suzuki Access</div>
                              <div className="text-wrapper-34">Rs. 65,673 - 79,442</div>
                            </div>
                          </div>
                        </div>
                        <div className="text-wrapper-35">Similar Bikes</div>
                        <div className="group-15">
                          <img className="rectangle-8" alt="Rectangle" src="/img/star-1.png" />
                          <div className="group-16">
                            <div className="group-17">
                              <div className="frame-19">
                                <div className="ellipse-2" />
                                <div className="text-wrapper-28">110cc</div>
                              </div>
                              <div className="frame-20">
                                <div className="ellipse-2" />
                                <div className="text-wrapper-29">50kmpl</div>
                              </div>
                              <div className="frame-21">
                                <img className="star-3" alt="Star" src="/img/star-1.png" />
                                <div className="text-wrapper-30">4.5</div>
                              </div>
                              <div className="text-wrapper-36">TVS NTORQ 125</div>
                            </div>
                            <div className="text-wrapper-37">Rs. 72,270 - 93,182</div>
                          </div>
                        </div>
                        <div className="frame-25">
                          <div className="ellipse-3" />
                          <div className="ellipse-4" />
                          <div className="ellipse-4" />
                        </div>
                      </div>
                    </div>
                    <div className="group-18">
                      <div className="group-19">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-38">Mileage</div>
                          <div className="text-wrapper-39">50 kmpl</div>
                        </div>
                      </div>
                      <div className="group-20">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-40">Fuel Tank Capacity</div>
                          <div className="text-wrapper-41">5.3 litres</div>
                        </div>
                      </div>
                      <div className="group-21">
                        <div className="overlap-group-5">
                          <div className="text-wrapper-42">Kerb Weight</div>
                          <div className="text-wrapper-43">107 Kg</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rectangle-9" />
                  <div className="group-22">
                    <div className="group-23">
                      <div className="overlap-group-6">
                        <img className="keyboard-arrow-up" alt="Keyboard arrow up" src="/img/keyboard-arrow-up.svg" />
                        <div className="text-wrapper-44">Next Bike</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="overlap-4">
                  <div className="group-24">
                    <div className="group-25">
                      <div className="overlap-group-7">
                        <img className="image" alt="Image" src="/img/image-1-1.png" />
                        <div className="color-wrapper">
                          <img className="color" alt="Color" src="/img/color.svg" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="rectangle-10" />
                  <div className="group-26">
                    <div className="frame-26">
                      <img className="search" alt="Search" src="/img/search.svg" />
                      <img className="pin-drop" alt="Pin drop" src="/img/pin-drop.svg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-wrapper-45">Automobile</div>
          <div className="text-wrapper-46">Web + Mobile App</div>
        </div>
        <div className="group-27">
          <div className="context">
            <div className="text-wrapper-47">Website + App Design</div>
          </div>
          <div className="scope-of-work">
            <div className="overlap-5">
              <img className="UIX" alt="Uix" src="/img/uix.png" />
              <div className="SM">
                <div className="overlap-6">
                  <div className="text-wrapper-48">Content</div>
                  <div className="group-28">
                    <div className="overlap-group-8">
                      <img className="line-2" alt="Line" src="/img/line-18.svg" />
                      <img className="line-3" alt="Line" src="/img/line-19.svg" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-49">Scope of Work</div>
            </div>
            <div className="WD">
              <div className="overlap-7">
                <div className="text-wrapper-50">App Development</div>
                <div className="group-28">
                  <div className="overlap-group-8">
                    <img className="line-2" alt="Line" src="/img/line-18-1.svg" />
                    <img className="line-3" alt="Line" src="/img/line-19-1.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img className="image-2" alt="Image" src="/img/image-1.png" />
      </div>
      <div className="the-challenge">
        <div className="group-29">
          <div className="text-wrapper-51">The Challenge</div>
          <p className="OTO-capital-brings">
            OTO Capital brings users the fastest way to get their hands on two-wheelers, with flexible payment options,
            Lower EMIs and more. We partnered with OTO to reimagine buying automobiles. So we took the off-beaten path -
            make the entire journey socially engaging plus immersive experiences. Buy vehicles with Video / Audio with
            multilingual support instead of making it just another static website with information &amp; Photos.
          </p>
        </div>
      </div>
      <div className="div-2">
        <div className="group-30">
          <div className="div-2">
            <div className="rectangle-11" />
            <div className="rectangle-12" />
            <img className="frame-27" alt="Frame" src="/img/frame-2269.png" />
          </div>
        </div>
      </div>
      <img className="img-2" alt="Element" src="/img/2.png" />
      <div className="outro">
        <div className="group-31">
          <div className="logo-wrapper">
            <img className="logo" alt="Logo" src="/img/logo.png" />
          </div>
        </div>
      </div>
    </div>
  );
};
